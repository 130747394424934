import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { NewPageLink } from '../utils/utils'

import MyPhoto from '../images/me.jpg'

const IndexPage = () => (
  <Layout>
    <h1>About Me!</h1>
    <img
      style={{ width: '100px', height: '100px' }}
      alt='Shervin Khastoo'
      src={MyPhoto}
    />
    <p>My name is Shervin Khastoo (Persian: شروین خستو). I was born on 1996/Feb/06 in Tehran, Iran.</p>
    <h1>Summary</h1>
    <p>
      I am currently working as a Software Engineer in Ontario, Canada and I have a master's degree in computer science from the <NewPageLink to='https://uwaterloo.ca'>University of Waterloo</NewPageLink>.
    </p>
    <p>
      You can get more information about me in the <Link to='/resume'>Resume</Link> page, and
      you can get my contact information from the <Link to='/contact'>Contact</Link> page.
    </p>
    <h1>Interests</h1>
    <p>Linux, Networking, Embedded Systems/IoT, Debugging/Hacking, DevOps, Backend/Frontend Development</p>
  </Layout>
)

export default IndexPage
